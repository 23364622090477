@font-face {
    font-family: 'montserratbold';
    src: url('/theme/ghana/fonts/Montserrat-Bold-webfont.eot');
    src: url('/theme/ghana/fonts/Montserrat-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/theme/ghana/fonts/Montserrat-Bold-webfont.woff') format('woff'),
         url('/theme/ghana/fonts/Montserrat-Bold-webfont.ttf') format('truetype'),
         url('/theme/ghana/fonts/Montserrat-Bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




.p7PMMh03 {
	width: auto;
	margin: 0;
	font-size: 16px;
	background-repeat: repeat-x;
	text-align: left;
	line-height: normal;
	float: right; 
  font-family: 'montserratbold', sans-serif;
  font-weight: normal;
  margin-top: 5px;
	letter-spacing: -1px;
	clear: right;
  letter-spacing: 0;
}

.main_content h4, .four_cols h4, .main_content h2, h1 {
    font-family: 'montserratbold', sans-serif;
    letter-spacing: 0;
    font-weight: normal;
}

.p7PMMh03 ul ul, .main_content p, .main_content ul, .footer p, .cart span a{
  font-family: "Open Sans",sans-serif;
}
.cart span a, .footer p{
  font-size: 14px;
}
.p7PMMh03 a:hover{
  color: $orange !important;
}