
.top_zag, .bottom_zag, .debug_button, .twitter_icon, .facebook_icon{
  display: none;
}
.right_colummn {
  width: 100%;
}
.masterhead{
  position: static;
  @media screen and (min-width: 768px) {
    position: fixed;
  }
}
.masterhead_inner{
  background-color: $background;
  @media screen and (min-width: 768px) {
    height: 143px;
  }
  .top-banner{
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      background-image : url('../images/asabaako-banner.png');
      background-repeat: no-repeat;
      top              : 0px;
      position         : absolute;
      left             : 610px;
      width            : 1028px;
      height           : 143px;
    }
  }
  .logo {
    top  : 29px;
    max-width: 243px;
    width: 135px;
    margin-top: 10px;
    position: static;
    float: left;
    height: auto;
    @media screen and (min-width: 768px) {
      width: auto;
      position: absolute;
    }
    img{
      width: 100%;
    }
  }
  .dates{
    display: none;
    width   : 160px;
    float: left;
    margin-top: 35px;
    margin-left: 12px;
    @media screen and (min-width: 768px) {
      margin: 0;
      top     : 58px;
      position: absolute;
      left    : 398px;
      width   : 204px;
    }
  }
  .social-container{
    background:rgba(255,255,255, 0.5);
    height: 40px;
    width: 100px;
    float: right;
    margin-top: 10px;
    @include rounded();
  }
}
.content{
  padding-top: 0;
  @media screen and (min-width: 768px) {
    padding-top: 151px;
  }
  @media screen and (min-width: 1200px) {
    padding-top: 0;
  }
  .full_width{
    margin-top: 17px;
    @media screen and (min-width: 1200px) {
      // background-image: url('../images/asabaako-left-banner.png');
      // background-repeat: repeat-y;
      // background-position: top left;
      @include clearfix();
    }
  }
  .two_thirds{
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 66.6666666%;
    }
    &.fullwidth_ghana{
      width: 100%;
    }
  }
  .one_third{
     width: 100%;
    @media screen and (min-width: 768px) {
      padding-left: 20px;
      width: 33.3333333%;
    }
    h6.file_caption{
      margin-top: -11px;
      margin-bottom: 11px;
    }
  }
  .image_half span.overlay {
    background-image : url('../images/overlay.png') !important;
    background-repeat: no-repeat !important;
  }
}
.footer{
  background-color: $background;
  padding-top: 10px;
}
.clear {
  clear: both;
  height: 0px;
}

.p7PMMh03 {
  margin: 114px 0 0 0;
  font-size: 14px;
  @media screen and (min-width: 768px) {
    font-size: 14px !important;
  }
  a{

    color: #FFF !important;
     @media screen and (min-width: 768px) {
      padding: 4px 7px;
      font-size:14px;
     }
     @media screen and (min-width: 800px) {
      padding: 4px 11px;
      font-size:14px;
     }
     @media screen and (min-width: 900px) {
      padding: 4px 11px;
      font-size:15px;
     }
     @media screen and (min-width:1200px) {
      padding: 4px 15px;
      font-size:16px;
     }
  }
}
.p7PMMh03 .current_mark, .p7PMMh03 .active {
    color: $orange !important;
    border-bottom: none;
}
.p7PMMh03 ul ul .current_mark {
    color: $orange !important;
    border-bottom: none;
}

#mc_embed_signup{
    width: 320px;
    margin: auto;
    text-align: center;
}
.social-icons{
  width: 32px;
  float: left;
  margin-left: 5px;
}

.cart{
  border: none;
  background-color: none;
  background: none;
  position: absolute;
  right: 0;
  width: 273px;
  height: 20px;
  text-align: left;
  top: 50px;
  @media screen and (min-width: 768px) {
    width: 273px;
    height: 20px;
    text-align: left;
    top: -7px;
  }
  span a{
    margin-right: 0;
    @media screen and (min-width: 768px) {
      margin-right: 20px;
    }
  }
  .arrow{
    display: none;
  }
}
hr {
    border-top: 2px dotted #f26522;
}
