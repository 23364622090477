// Default font
@mixin typeface {
    // From: Better Helvetica Font Stack http://j.mp/9t6O6Z
    font-family: "Helvetica", Arial, sans-serif;
}

@mixin clearfix() {
    // http://nicolasgallagher.com/micro-clearfix-hack/
    // USAGE: @include clearfix();
    & {
        *zoom: 1;
    }
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}


@mixin rounded($radius: 0.5em) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}


@function em($target, $context: 14) {
    @return ($target / $context) * 1em;
}
