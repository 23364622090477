html .content {
  box-sizing: border-box;
}
.content *, .content *:before, .content *:after {
  box-sizing: border-box;
}


.content a{
  text-decoration: none;
}
