/*
Theme Name :    Ghana Theme
Description:    Theme for Festival CMS
Author     :    L
Version    :    0.1
*/



@import "base/mixins"; // Helper classes and mixins
@import "base/variables";
@import "base/base"; // Generic Styles that are not attached to specific modules
@import "base/typography"; // Site wide typography rules


@import "layout/layout"; // Global Layout Components like Header and Footer - could be broken up into individual stylesheets


// Carousel Dropdown Navigation etc
@import "components/buttons";
@import "components/forms";
@import "components/nav";
