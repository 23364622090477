.anchorLink span{
  color: black;
}

table.coach_times .sold_out {
  width: 110px;
}
.add_cart_td{
  width: 137px;
}
.remove_from_cart_style{
  width: auto;
}
