$arial-font-stack: Arial,"Helvetica Neue", Helvetica, sans-serif;
$verdana-font-stack: Verdana, Geneva, sans-serif;

$blue: #5390cc;

$background: #1BA49C;

$orange: #F58434;

.main_content .color_scheme{
  color: $orange;
}
.add_to_cart_coach, .add_to_bag_hostel, .add_to_cart_otheritem{
  background-color: $orange;
}